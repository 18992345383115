import { Box } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Container } from "../components/container"
import { Layout } from "../components/layout"
import { Title } from "../components/title"
import { PostThumbnail } from "../components/blog/post-thumbnail"
import { SEO } from "../components/seo"

const Blog: FunctionComponent<{ data: any }> = ({ data }) => {
  const blogposts = data.allPrismicBlogposts.edges

  return (
    <Layout>
      <Box
        position="fixed"
        h="100vh"
        w="100vw"
        bg="#fff"
        zIndex={-1}
        opacity={0.35}
      />
      <Container>
        <Title text="BLOG" />
        <SEO title="BLOG" />

        {blogposts?.map((blogpost: any, index: number) => {
          const { data, id, first_publication_date } = blogpost.node
          return (
            <PostThumbnail
              key={index}
              title={data.title?.text}
              cover={data.cover.url}
              intro={data.intro?.text}
              published={data.published_at}
              uid={id}
            />
          )
        })}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Posts {
    allPrismicBlogposts(sort: { fields: data___published_at, order: DESC }) {
      edges {
        node {
          id
          first_publication_date(formatString: "YYYY. MM. DD.")
          last_publication_date
          data {
            published_at(formatString:"YYYY. MM. DD.")
            title {
              text
            }
            intro {
              text
            }
            cover {
              url
            }
            content {
              text
            }
          }
        }
      }
    }
  }
`

export default Blog
